import {
  CLEAR_BILTY_DATA,
  COLLECT_BILTY_DATA,
  GOODS_DATA,
  ADD_BILTY,
  BILTY_NUMBER,
  SINGLE_BILTY_DATA,
  DELETE_ROW,
  UPDATE_ROW_DATA,
  ROUTE_DATA,
  ADD_BILTY_TO_MEMO,
  TRACK_BILTY,
  SET_NOR,
  SET_NEE,
  CLEAR_SINGLE_BILTY_DATA,
} from '../constant/constant';

const initialState = {
  date: '',
  norname: null,
  norcity: '',
  norgstin: '',
  normobile: '',
  altnormobile: '',
  norpincode: '',
  neename: null,
  co: '',
  neegstin: '',
  neemobile: '',
  altneemobile: '',
  neecity: '',
  neepincode: '',
  invno: '',
  invdate: '',
  invamt: '',
  ewaybill: '',
  distance: '',
  mop: '',
  dloffice: '',
  goods: [],
  rowtotalamt: '',
  frcharges: '',
  hamali: '',
  doccharges: 10,
  totalqty: '',
  ddcharges: '',
  othercharges: '',
  gst: '',
  totalfr: '',
  remark: '',
  return: false,
};

export const BiltyReducer = function (state = initialState, action) {
  switch (action.type) {
    case COLLECT_BILTY_DATA: {
      return action.payload;
    }
    case CLEAR_BILTY_DATA: {
      return action.payload;
    }

    case GOODS_DATA: {
      return action.payload;
    }

    case ADD_BILTY: {
      return state;
    }
    case SET_NOR: {
      return {
        ...state,
        norname: action.payload,
        norgstin: action.payload?.gstin,
        normobile: action.payload?.mobile,
        norpincode: action.payload?.pincode,
        norcity: action.payload.city,
        mop: action.payload.mop,
      };
    }
    case SET_NEE: {
      return {
        ...state,
        neename: action.payload,
        neegstin: action.payload?.gstin,
        neemobile: action.payload?.mobile,
        neepincode: action.payload?.pincode,
        neecity: action.payload.city,
      };
    }
    default: {
      return state;
    }
  }
};

export const biltyNumber = function (state = 0, action) {
  switch (action.type) {
    case BILTY_NUMBER:
      return action.payload + 1;
    default: {
      return state;
    }
  }
};

export const singleData = function (state = 0, action) {
  switch (action.type) {
    case SINGLE_BILTY_DATA:
      return action.payload;
    case CLEAR_SINGLE_BILTY_DATA:
      return action.payload
    default: {
      return state;
    }
  }
};

export const getRouteData = function (state = [], action) {
  switch (action.type) {
    case ROUTE_DATA:
      return action.payload;
    case DELETE_ROW: {
      return state.filter((e) => e._id !== action.payload.id);
    }
    case UPDATE_ROW_DATA:
      return action.payload;
    case ADD_BILTY_TO_MEMO: {
      const updatedPayload = {
        ...action.payload,
        // serialNumber: state.length + 1 
      };
      return [...state, updatedPayload];
    }
    default: {
      return state;
    }
  }
};

export const trackBilty = function(state=0,action){
  switch(action.type){
    case TRACK_BILTY:{
      return action.payload}
      default: {
        return state;
      }
  }
 
}