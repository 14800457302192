// import '../fake-db';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import routes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../app.css';
import '../print.css';
import { Store } from './redux/Store';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import WifiOffIcon from '@mui/icons-material/WifiOff';
const App = () => {
  const content = useRoutes(routes);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });
  }, []);
  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the installation prompt');
        } else {
          console.log('User dismissed the installation prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(deferredPrompt){
        handleClickOpen()
      }
    }, 200);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [deferredPrompt]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);


  return (
    <Provider store={Store}>
      <SettingsProvider>
        <MatxTheme>
          <AuthProvider>{content}</AuthProvider>
        </MatxTheme>
        <ToastContainer
          position="bottom-left"
          autoClose={3500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          // theme="light"
          theme="colored"
        />
        {/* <button onClick={handleClickOpen} style={{ display: deferredPrompt ? 'block' : 'none' }}>
          Install App
        </button> */}

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Install the PWA App</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Click the "Install" button below to add this app to your home screen.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleInstallClick} color="primary">
              Install
            </Button>
          </DialogActions>
        </Dialog>


        <Dialog
        open={!isOnline}
        onClose={isOnline}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{color:"red"}} id="alert-dialog-title">
         <WifiOffIcon/> {"Offline"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <p>You are currently offline. Please check your internet connection.</p>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
